<template>
  <div class="page-main-bg half-circle-bg">
    <v-container class="main-container">
      <BackgroundShapes></BackgroundShapes>

      <v-toolbar
        height="80px"
        color="elevation-0"
        id="page-tollbar"
        class="my-5 narrow-toolbar"
      >
        <div class="page-title">
          <v-toolbar-title>إدارة الخطابات</v-toolbar-title>
        </div>
      </v-toolbar>

      <v-alert dense type="info" class="center-align-icon mb-6">
        <v-row align="center">
          <v-col class="grow"> ليس لديك عقودات مضافة في الوقت الحالي </v-col>
          <v-col class="shrink pa-0">
            <v-btn class="ma-0 ml-3" color="info" filled>
              <span class="tf">أضف عقد جديد</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-list
        three-line
        subheader
        class="noti-list mb-4 position-relative shadow-border-radius"
      >
        <v-subheader inset>قائمة الخطابات</v-subheader>

        <template v-for="(item, i) in items">
          <v-list-item
            :key="item.title"
            class="pt-3 pb-6"
            :class="{ active: item.value }"
          >
            <v-list-item-avatar :class="`shadow-${item.iconClass}`">
              <v-icon :class="[item.iconClass]" v-text="item.icon"></v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <v-list-item-subtitle
                v-text="item.subtitle"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-switch
                :loading="item.loadding"
                @change="onSwitchChange(i)"
                color="green"
                value
                :input-value="item.value"
              ></v-switch>

              <v-tooltip right :open-on-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    fab
                    color="primary"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    @click.prevent="openAddDialog"
                  >
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>تعديل الخطاب</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-container>

    <!-- Add New Unit Modal -->
    <EditLetterModal
      :dialogState="dialogState"
      @changeDialogState="dialogState = $event"
      @closed-add-dialog="dialogState = false"
    ></EditLetterModal>
  </div>
</template>

<script>
import config from '@/config'
import { mapMutations } from 'vuex'
import '@/styles/material-dashboard/_properties.scss'
import EditLetterModal from '@/components/modals/EditLetterModal.vue'
import BackgroundShapes from '@/components/bg/BackgroundShapes.vue'
export default {
  components: {
    BackgroundShapes,
    EditLetterModal
  },
  data: () => {
    return {
      items: [
        {
          id: 0,
          icon: 'mdi-timer-outline',
          iconClass: 'success white--text',
          title: 'خطاب حلول المستحقات',
          subtitle:
            'شكرا لك على تفضيلك العقار، نتمنى أن يحوز العقار على اعجابك ونسعد بزيارتك في الوقت المناسب لك لمعاينة العقار على الحقيقة، متأكدين انه سينالك اعجابك  اكثر، في انتظارك سيد نهر الأصبهاني الخرساني، لوريم ابسم عربي',
          loadding: false,
          value: false
        },

        {
          id: 1,
          icon: 'mdi-timer-off-outline',
          iconClass: 'warning white--text',
          title: 'خطاب تأخر المستحقات',
          subtitle:
            'شكرا لك على تفضيلك العقار، نتمنى أن يحوز العقار على اعجابك ونسعد بزيارتك في الوقت المناسب لك لمعاينة العقار على الحقيقة، متأكدين انه سينالك اعجابك اكثر، في انتظارك سيد نهر الأصبهاني الخرساني، لوريم ابسم عربي',
          loadding: false,
          value: true
        },

        {
          id: 2,
          icon: 'mdi-bed-double-outline',
          iconClass: 'info white--text',
          title: 'خطاب إخلاء عقار',
          subtitle:
            'شكرا لك على تفضيلك العقار، نتمنى أن يحوز العقار على اعجابك  ونسعد بزيارتك في الوقت المناسب لك لمعاينة العقار على الحقيقة، متأكدين انه سينالك اعجابك اكثر، في انتظارك سيد نهر الأصبهاني الخرساني، لوريم ابسم عربي',
          loadding: false,
          value: true
        }
      ],
      page: 1,
      gridView: true,
      dialogState: false,

      halfcircle1: require('@/assets/img/half-circle-1.svg'),
      halfcircle2: require('@/assets/img/half-circle-2.svg'),
      dots: require('@/assets/img/dots-3.svg'),
      dots2: require('@/assets/img/dots-1.svg')
    }
  },
  computed: {
    properties() {
      return config.properties
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification', 'toggleDrawer']),
    openAddDialog() {
      this.dialogState = true
    },
    onSwitchChange(i) {
      this.items[i].loadding = true

      setTimeout(() => {
        this.items[i].loadding = false
        this.items[i].value = !this.items[i].value

        this.addNotification({
          text: 'تم تغير حالة الخطاب',
          color: 'success'
        })
      }, 1000)
    }
  },
  metaInfo() {
    return {
      title: 'إدارة الخطابات'
    }
  }
}
</script>

<style lang="scss">
.noti-list {
  .v-list-item {
    &:not(:last-child) {
      border-bottom: 1px solid #eff2f7;
    }

    &:not(.active) {
      opacity: 0.6;
    }
  }

  // .v-list-item__content {
  //   max-width: 600px;
  // }

  // .v-list-item__action--stack {
  //   margin-right: auto !important;
  //   flex-direction: row;
  //   align-items: center;
  // }

  // .v-list-item__action .v-input {
  //   margin-left: 20px !important;
  // }
}
</style>
